import React from "react";
import * as Yup from "yup";
import { Checkbox, List } from "semantic-ui-react";
import { Field, Form, Formik, ErrorMessage } from "formik";

import { TextInput } from "../form-components/CustomInput";
import { CategoryProductCheckbox } from "../order-display/components/CategoryProductCheckbox";
import "./sie-accounts.scss";
import {
    CardType,
    PaymentMethod,
    SIE_ACCOUNT_TYPES,
    ONLINE_SERVICES,
    THIRD_PARTY_DELIVERY_TYPE_IN_SWEDISH
} from "Constants";
import { cardText, swedishPaymentMethod } from "Utils";
import { SIEAccount, AlreadySelectedValuesType } from "./AccountingAccounts";
import { Box, FormErrorMessage, Button } from "Atoms";
import { useLanguage } from "LanguageProvider";

type CategoryIdName = {
    id: string;
    name: string;
};

type Props = {
    accountType: string;
    sieAccount: SIEAccount;
    upsertAccount: (values: SIEAccount, accountType: string) => void;
    isEdit: boolean;
    bundleAndRefProducts: any[];
    refCategories: CategoryIdName[];
    onClose: () => void;
    alreadySelectedValues: AlreadySelectedValuesType;
    vatRates: number[];
    thirdPartyServices: string[];
    alreadyUsedAccountNumbers: number[];
};

const SIE_FIELD_CONSTANTS = {
    PRODUCT_ID: "productIds",
    CATEGORY_ID: "categoryIds",
    PAYMENT_METHODS: "paymentMethods",
    PRODUCT_AND_CATEGORIES: "productAndCategories",
    VAT: "vatRates",
    CARD_TYPES: "cardTypes",
    TIP: "cardTypes",
    ONLINE_SALE_METHODS: "onlineSaleMethods",
    ONLINE_PAYMENT_METHODS: "onlinePaymentMethods"
};

type SIE_CONSTANT = keyof typeof SIE_FIELD_CONSTANTS;

type SwedishPaymentMethod = keyof typeof swedishPaymentMethod;

export const SIEAccountForm: React.FC<Props> = ({
    accountType,
    sieAccount,
    upsertAccount,
    isEdit,
    bundleAndRefProducts,
    refCategories,
    onClose,
    alreadySelectedValues,
    vatRates,
    thirdPartyServices,
    alreadyUsedAccountNumbers
}) => {
    const { translate } = useLanguage();

    const validationSchema = () => {
        const required = Yup.string().required(translate("formErrorMissing"));
        const account = Yup.number()
            .required(translate("formErrorMissing"))
            .test("len", translate("accountNumberError"), val => /^\d{4}$/.test(val))
            .test("unique", translate("accountNumberUsed"), val => !alreadyUsedAccountNumbers.includes(val));
        switch (accountType) {
            case SIE_ACCOUNT_TYPES.PAYMENT_METHODS:
                return Yup.object().shape({
                    name: required,
                    paymentMethods: Yup.array().when("cardTypes", {
                        is: cardTypes => cardTypes.length === 0,
                        then: Yup.array().min(1, translate("siePaymentMethodError"))
                    }),
                    cardTypes: Yup.array().min(0),
                    accountNumber: account
                });
            case SIE_ACCOUNT_TYPES.ONLINE_SALE_METHODS:
                return Yup.object().shape({
                    name: required,
                    accountNumber: account,
                    onlineSaleMethods: Yup.array().min(1, translate("sieOnlineSaleMethodError"))
                });
            case SIE_ACCOUNT_TYPES.PRODUCT_AND_CATEGORIES:
                return Yup.object().shape(
                    {
                        name: required,
                        accountNumber: account,
                        productIds: Yup.string().when("categoryIds", {
                            is: categoryIds => !categoryIds || categoryIds.length === 0,
                            then: Yup.string().required(translate("sieProductError"))
                        }),
                        categoryIds: Yup.string().when("productIds", {
                            is: productIds => !productIds || productIds.length === 0,
                            then: Yup.string().required(translate("sieProductError"))
                        })
                    },
                    //@ts-ignore
                    ["categoryIds", "productIds"]
                );
            case SIE_ACCOUNT_TYPES.VAT:
                return Yup.object().shape({
                    name: required,
                    vatRates: Yup.array().min(1, translate("sieVATError")),
                    accountNumber: account
                });
            case SIE_ACCOUNT_TYPES.TIP:
                return Yup.object().shape({
                    name: required,
                    cardTypes: Yup.array().min(1, translate("siePaymentMethodError")),
                    accountNumber: account
                });
        }
    };

    const buttonText = isEdit ? translate("update") : translate("save");

    const accountTypeComponent = (values: SIEAccount, toggleValuesFunction: any, helperText?: any) => {
        const getMarginBottom =
            helperText && helperText[SIE_FIELD_CONSTANTS[accountType as SIE_CONSTANT]] ? "0px" : "1em";
        const getMarginErrorMsg =
            helperText && helperText[SIE_FIELD_CONSTANTS[accountType as SIE_CONSTANT]] ? "1em" : "0em";

        switch (accountType) {
            case SIE_ACCOUNT_TYPES.PAYMENT_METHODS:
                return (
                    <>
                        {`${translate("paymentOptions")} - ${translate("kassa")}`}
                        {/** @ts-ignore */}
                        <List className="sie-cat-prods">
                            {[
                                PaymentMethod.CARD,
                                PaymentMethod.CASH,
                                PaymentMethod.SWISH,
                                PaymentMethod.INVOICE,
                                PaymentMethod.KLARNA
                            ].map(paymentMethod => {
                                const isInvoice = paymentMethod == PaymentMethod.INVOICE;
                                return (
                                    <List.Item key={paymentMethod} style={{ paddingLeft: "10px" }}>
                                        <Checkbox
                                            disabled={
                                                (alreadySelectedValues.paymentMethods?.includes(paymentMethod) &&
                                                    !sieAccount?.paymentMethods?.includes(paymentMethod)) ||
                                                isInvoice
                                            }
                                            label={`${
                                                swedishPaymentMethod[paymentMethod as SwedishPaymentMethod].swe
                                            }  ${isInvoice ? "(" + translate("notAvailable") + ")" : ""}`}
                                            defaultChecked={values?.paymentMethods?.includes(paymentMethod)}
                                            onChange={(_, { checked }) =>
                                                toggleValuesFunction(
                                                    SIE_FIELD_CONSTANTS.PAYMENT_METHODS,
                                                    paymentMethod,
                                                    checked
                                                )
                                            }
                                        />
                                    </List.Item>
                                );
                            })}
                            {[CardType.AMEX].map(cardType => (
                                <List.Item key={cardType} style={{ paddingLeft: "10px" }}>
                                    <Checkbox
                                        disabled={
                                            alreadySelectedValues.cardTypes?.includes(cardType) &&
                                            !sieAccount?.cardTypes?.includes(cardType)
                                        }
                                        //@ts-ignore
                                        label={cardText[cardType]}
                                        defaultChecked={values?.cardTypes?.includes(cardType)}
                                        onChange={(_, { checked }) =>
                                            toggleValuesFunction(SIE_FIELD_CONSTANTS.CARD_TYPES, cardType, checked)
                                        }
                                    />
                                </List.Item>
                            ))}
                        </List>
                        <Box marginBottom={getMarginErrorMsg}>
                            <ErrorMessage name="paymentMethods" component={FormErrorMessage} />
                        </Box>
                        {translate("paymentOptions")} - online
                        {/** @ts-ignore */}
                        <List className="sie-cat-prods">
                            {[PaymentMethod.GIFT_CARD].map(paymentMethod => (
                                <List.Item key={paymentMethod} style={{ paddingLeft: "10px" }}>
                                    <Checkbox
                                        disabled={
                                            alreadySelectedValues.paymentMethods?.includes(paymentMethod) &&
                                            !sieAccount?.paymentMethods?.includes(paymentMethod)
                                        }
                                        label={swedishPaymentMethod[paymentMethod as SwedishPaymentMethod].swe}
                                        defaultChecked={values?.paymentMethods?.includes(paymentMethod)}
                                        onChange={(_, { checked }) =>
                                            toggleValuesFunction(
                                                SIE_FIELD_CONSTANTS.PAYMENT_METHODS,
                                                paymentMethod,
                                                checked
                                            )
                                        }
                                    />
                                </List.Item>
                            ))}
                        </List>
                    </>
                );
            case SIE_ACCOUNT_TYPES.ONLINE_SALE_METHODS:
                return (
                    <>
                        {translate("paymentOptions")}
                        {/** @ts-ignore */}
                        <List className="sie-cat-prods">
                            {[...thirdPartyServices, ONLINE_SERVICES.WEB].map(service => (
                                <List.Item key={service} style={{ paddingLeft: "10px" }}>
                                    <Checkbox
                                        disabled={
                                            alreadySelectedValues.onlineSaleMethods?.includes(service) &&
                                            !sieAccount?.onlineSaleMethods?.includes(service)
                                        }
                                        label={
                                            service === "WEB"
                                                ? "Qopla online"
                                                : THIRD_PARTY_DELIVERY_TYPE_IN_SWEDISH[service]
                                        }
                                        defaultChecked={values?.onlineSaleMethods?.includes(service)}
                                        onChange={(_, { checked }) =>
                                            toggleValuesFunction(
                                                SIE_FIELD_CONSTANTS.ONLINE_SALE_METHODS,
                                                service,
                                                checked
                                            )
                                        }
                                    />
                                </List.Item>
                            ))}
                        </List>
                    </>
                );
            case SIE_ACCOUNT_TYPES.PRODUCT_AND_CATEGORIES:
                return (
                    <>
                        {translate("productsAndCategories")}
                        {/** @ts-ignore */}
                        <List
                            className="sie-cat-prods"
                            style={{
                                marginBottom: helperText && helperText[SIE_FIELD_CONSTANTS.PRODUCT_ID] ? "0em" : "1em"
                            }}
                        >
                            {refCategories.map(cat => (
                                <CategoryProductCheckbox
                                    key={cat.id}
                                    products={bundleAndRefProducts}
                                    hiddenCategories={values.categoryIds}
                                    hiddenProducts={values.productIds}
                                    toggleHiddenCategory={(id: string, checked: boolean) =>
                                        toggleValuesFunction(SIE_FIELD_CONSTANTS.CATEGORY_ID, id, checked)
                                    }
                                    toggleHiddenProduct={(id: string, checked: boolean) =>
                                        toggleValuesFunction(SIE_FIELD_CONSTANTS.PRODUCT_ID, id, checked)
                                    }
                                    cat={cat}
                                    disabledProdCatIdsSet={alreadySelectedValues.prodAndCat}
                                    selectedFromStart={
                                        new Set(sieAccount?.productIds?.concat(sieAccount?.categoryIds || []))
                                    }
                                />
                            ))}
                        </List>
                        <Box marginBottom={helperText && helperText[SIE_FIELD_CONSTANTS.PRODUCT_ID] ? "1em" : "0em"}>
                            <ErrorMessage name="productIds" component={FormErrorMessage} />
                        </Box>
                    </>
                );
            case SIE_ACCOUNT_TYPES.VAT:
                return (
                    <>
                        {translate("vatRates")}
                        {/** @ts-ignore */}
                        <List className="sie-cat-prods" style={{ marginBottom: getMarginBottom }}>
                            {vatRates.map(vatRate => (
                                <List.Item key={vatRate} style={{ paddingLeft: "10px" }}>
                                    <Checkbox
                                        disabled={
                                            (alreadySelectedValues.vatRates?.includes(vatRate) &&
                                                !sieAccount?.vatRates?.includes(vatRate)) ||
                                            ((values?.vatRates || []).length > 0 &&
                                                !values?.vatRates?.includes(vatRate))
                                        }
                                        label={`${vatRate}%`}
                                        defaultChecked={values?.vatRates?.includes(vatRate)}
                                        onChange={(_, { checked }) =>
                                            toggleValuesFunction(SIE_FIELD_CONSTANTS.VAT, vatRate, checked)
                                        }
                                    />
                                </List.Item>
                            ))}
                        </List>
                        <Box marginBottom={getMarginErrorMsg}>
                            <ErrorMessage name="vatRates" component={FormErrorMessage} />
                        </Box>
                    </>
                );
            case SIE_ACCOUNT_TYPES.TIP:
                return (
                    <>
                        {translate("tipsFromCards")}
                        {/** @ts-ignore */}
                        <List className="sie-cat-prods" style={{ marginBottom: getMarginBottom }}>
                            {[CardType.MC_VISA, CardType.AMEX].map(cardType => (
                                <List.Item key={cardType} style={{ paddingLeft: "10px" }}>
                                    <Checkbox
                                        disabled={
                                            alreadySelectedValues.tipAccounts?.includes(cardType) &&
                                            !sieAccount?.cardTypes?.includes(cardType)
                                        }
                                        label={`${cardType === CardType.AMEX ? "Amex" : "MC/Visa"}`}
                                        defaultChecked={values?.cardTypes?.includes(cardType)}
                                        onChange={(_, { checked }) =>
                                            toggleValuesFunction(SIE_FIELD_CONSTANTS.TIP, cardType, checked)
                                        }
                                    />
                                </List.Item>
                            ))}
                        </List>
                        <Box marginBottom={getMarginErrorMsg}>
                            <ErrorMessage name="cardTypes" component={FormErrorMessage} />
                        </Box>
                    </>
                );
        }
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={sieAccount}
            validationSchema={validationSchema()}
            onSubmit={values => upsertAccount(values, accountType)}
            render={({ values, setFieldValue, isValid, errors, isSubmitting, setFieldTouched }) => {
                const toggleValuesFunction = (field: string, value: string | number, checked: boolean) => {
                    if (checked) {
                        // @ts-ignore
                        setFieldValue(field, values[field].concat([value]), false);
                    } else {
                        setFieldValue(
                            field,
                            // @ts-ignore
                            values[field].filter(id => id !== value)
                        );
                    }
                    setFieldTouched(field);
                };

                return (
                    <Form>
                        <Field
                            label={translate("name")}
                            name="name"
                            placeholder={translate("fillInNameOnAccount")}
                            component={TextInput}
                        />
                        <Field
                            label={translate("description")}
                            name="description"
                            placeholder={translate("fillInDescription")}
                            component={TextInput}
                        />
                        <Field
                            label={translate("accountNumber")}
                            name="accountNumber"
                            placeholder={translate("enterAccountNumber")}
                            type="number"
                            component={TextInput}
                        />
                        {accountTypeComponent(values, toggleValuesFunction, errors)}
                        <Button onClick={onClose} themeColor="red" mr={4}>
                            {translate("close")}
                        </Button>

                        <Button type="submit" themeColor="green" disabled={!isValid || isSubmitting}>
                            {buttonText}
                        </Button>
                    </Form>
                );
            }}
        />
    );
};
