import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { UPSERT_SERVICE_DISRUPTIONS } from "GraphQLMutations";
import { GET_SERVICE_DISRUPTIONS } from "GraphQLQueries";
import { useMothershipMutation, useMothershipQuery } from "Hooks";
import { Button, RHCheckBoxInput, Flex, Spinner } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { confirmNotification } from "TempUtils";
import { ServiceDisruption, TypeOfServiceDisruption } from "Types";
import { useAccordionItemContext } from "Organisms";

export type ServiceDisruptionQuery = {
    getDisruptedServices: ServiceDisruption[];
};

type ServiceDisruptionsFormValues = {
    selectedServices: ServiceDisruption[];
};

type ServiceDisruptionMutation = {
    upsertServiceDisruptions: ServiceDisruption[];
};

const getNewDisruption = (type: TypeOfServiceDisruption) => {
    return { typeOfServiceDisruption: type, setOnAllShops: true, serviceDisrupted: false };
};

export const ServiceDisruptions: React.FC = () => {
    const { colors } = useTheme();
    const { translate } = useLanguage();
    const { isExpanded } = useAccordionItemContext();

    const {
        data: disruptedServicesData,
        loading: loadingServiceDisruptions,
        updateQuery
    } = useMothershipQuery<ServiceDisruptionQuery>(GET_SERVICE_DISRUPTIONS, {
        fetchPolicy: "network-only",
        skip: !isExpanded
    });

    const [setServiceDisruptions] = useMothershipMutation<ServiceDisruptionMutation>(UPSERT_SERVICE_DISRUPTIONS);

    const disruptedServices: ServiceDisruption[] = disruptedServicesData?.getDisruptedServices ?? [];

    /** Get all possible types of disruption */
    const newDisruptionValues: ServiceDisruption[] = Object.values(TypeOfServiceDisruption).map(
        (type: TypeOfServiceDisruption) => {
            return getNewDisruption(type);
        }
    );

    /** Query collects current disruptions
     * Swaps out new for ongoing disruption
     */
    const formValues = newDisruptionValues.map(newValue => {
        const updatedServiceIsPresent = disruptedServices.find(
            service => service.typeOfServiceDisruption === newValue.typeOfServiceDisruption
        );

        return !!updatedServiceIsPresent ? updatedServiceIsPresent : newValue;
    });

    const {
        control,
        handleSubmit,
        formState: { isDirty, isSubmitting },
        reset
    } = useForm<ServiceDisruptionsFormValues>({
        defaultValues: { selectedServices: formValues }
    });

    const { fields } = useFieldArray({ control, name: "selectedServices" });

    useEffect(() => {
        if (disruptedServicesData) {
            reset({ selectedServices: formValues });
        }
    }, [disruptedServices]);

    if (loadingServiceDisruptions || !disruptedServicesData) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const onSubmit = async (formValues: ServiceDisruptionsFormValues) => {
        const confirm = await confirmNotification(
            translate("areYouSure"),
            translate("thisEnablesOrDisablesOnlinePayments"),
            translate("yes"),
            colors.green["500"]
        );
        if (confirm.value) {
            /**
             * 1. If disruption type has an id then that needs to be saved
             * 2. If it is a new disruption, also needs to be saved
             * */
            const selectedServices =
                formValues.selectedServices.reduce((service: ServiceDisruption[], current: ServiceDisruption) => {
                    const shouldUpdateDisruption = Reflect.has(current, "id");
                    if (shouldUpdateDisruption || current.serviceDisrupted) {
                        return [...service, current];
                    }
                    return service;
                }, []) ?? [];

            if (selectedServices.length) {
                setServiceDisruptions({ variables: { serviceDisruptions: selectedServices } }).then(({ data }) => {
                    /** Will only return disrupted services */
                    if (data?.upsertServiceDisruptions) {
                        const { upsertServiceDisruptions } = data;
                        updateQuery((_: any) => {
                            return {
                                getDisruptedServices: upsertServiceDisruptions
                            };
                        });
                        toast.success(translate("changesHaveBeenSaved"));
                    } else {
                        toast.error(translate("somethingWentWrong"));
                    }
                });
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((field, index) => (
                <RHCheckBoxInput
                    name={`selectedServices.${index}.serviceDisrupted`}
                    control={control}
                    key={field.typeOfServiceDisruption}
                >
                    {translate(field.typeOfServiceDisruption)}
                </RHCheckBoxInput>
            ))}
            <Button type="submit" themeColor="green" disabled={!isDirty} isLoading={isSubmitting} fullWidth>
                {translate("save")}
            </Button>
        </form>
    );
};
